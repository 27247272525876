import React from 'react'
import { navigate } from 'gatsby'
import { Layout } from '../components'
import { Register } from '../components/authForms'
import { useApp } from '../context/appContext'
import './login.scss'
import '../components/authForms/authForms.scss'

const Auth = () => {
  const { isAuthenticated } = useApp()

  if (isAuthenticated()) {
    navigate('/user/dashboard')
  }

  return (
    <Layout>
      <div className="section-register section-inner section-inner--xs vertical-indent--m">
        <h2>Регистрация</h2>
        <Register />
      </div>
    </Layout>
  )
}

export default Auth
